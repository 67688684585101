import React, { useEffect, useState } from "react"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { discountPrice, isEmptyObj } from "../../include/function"
import Spinner from "../add-ons/Spinner"

const CustomerBody = () => {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [categoryList, setCategoryList] = useState({})

    useEffect(() => {
        getHomeCategory()
    }, [])

    const getHomeCategory = () => {
        setFetchLoading(true)
        api.get('/category/?flag=getHomeAndChilds', customerReqConfig()).then(response => {
            if (response.status === 200) {
                setCategoryList(response.data.data[0])
            }
        }).catch(error => {
            setCategoryList({})
        }).finally(() => setFetchLoading(false))
    }

    return fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> : <div className="container">
        {!isEmptyObj(categoryList) && categoryList.childs.map(child => {
            return child.products && <ChildCategory child={child} key={nanoid()} />
        })}
    </div>
}

const ChildCategory = ({ child }) => {
    return child.products.length > 0 && <div className="w-100 mt-3">
        <div className="p-3">
            <h5 className="m-0 fw-bold text-dark">{child.name}</h5>
        </div>
        <div className="container">
            <div className="row">
                {child.products.map(product => {
                    return <Product product={product} key={nanoid()} />
                })}
            </div>
        </div>
    </div>
}

const Product = ({ product }) => {
    return <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
        <Link to={"/product/" + product.productId} className="text-center d-block p-3 border my-2 text-dark">
            <img className="fit-contain img-nor hover-scale" src={product.mainImage && baseURL + '/' + product.mainImage} alt="" />
            <h6 className="my-2 text-truncate">{product.name}</h6>
            {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0 ?
                <h6 className="fw-bold">&#8377;{Math.ceil(product.price)}</h6> :
                <h6 className="fw-bold">
                    <span>&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                    <del className="text-muted mx-2">&#8377;{Math.ceil(product.price)}</del>
                    <span className="text-success">{product.discountLabel}</span>
                </h6>}
        </Link>
    </div>
}

export default CustomerBody
